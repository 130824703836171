













import { BoardModel } from '@technology/collaborate-next-js/dist/types/models'
import { Component, Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  name: 'dashboard-collaborate-common-inputs-board-select',
})
export default class BoardSelect extends Vue {
  @Prop()
  public groupId: string
  @Prop({
    default: 'all',
  })
  public value: string

  public currentName = 'All'
  public defaultValue = 'all'
  public localIds: string[] = []

  get items() {
    return [
      {
        id: 'all',
        name: 'All',
      },
      ...this.localIds
        .map(localId => this.$store.myGetters.getBoardById(localId))
        .filter(b => !b.isHidden)
        .sort((a: BoardModel, b: BoardModel) => {
          const aName = a.name.toLowerCase()
          const bName = b.name.toLowerCase()
          return aName > bName ? 1 : aName < bName ? -1 : 0
        }),
    ]
  }

  @Watch('value')
  public onValueChanged(to: string) {
    this.setCurrentName(to)
  }

  public handleClick(item: KeyMap<string>) {
    this.currentName = item.name
    this.$emit('change', item.id)
  }

  public async created() {
    try {
      const result = await this.$store.myActions.fetchBoards({
        groupId: this.groupId,
      })
      this.localIds = result.data.map(board => board.id)
      this.setCurrentName(this.value)
    } catch (err) {
      console.log(err)
    }
  }

  private setCurrentName(boardId: string) {
    const board = this.items.find(item => item.id === boardId)
    this.currentName = board ? board.name : this.defaultValue
  }
}
