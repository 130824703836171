









































import { Component } from 'vue-property-decorator'
import { TaskArticleModel, TaskModel } from '@technology/collaborate-next-js/dist/types/models'
import Vue from 'vue'

import CBoardDisplay from '~/components/dashboard/widget/collaborate/common/displays/BoardDisplay.vue'
import CPubDateDisplay from '~/components/dashboard/widget/collaborate/common/displays/PubDateDisplay.vue'
import CloseHeader from '~/components/common/widget/dialogs/CloseHeader.vue'
import TitleDisplay from '~/components/common/widget/displays/TitleDisplay.vue'
import eventhub from '~/components/dashboard/widget/collaborate/dailyTaskListWidget/eventhub'

@Component({
  components: {
    CloseHeader,
    TitleDisplay,
    CBoardDisplay,
    CPubDateDisplay,
  },
  name: 'dashboard-collaborate-daily-task-list-widget-view-details-dialog',
})
export default class Dialog extends Vue {
  public dialog = false
  public task: Nullable<TaskModel> = null
  public stories: Nullable<TaskArticleModel[]> = null
  public config: Nullable<KeyMap<any>> = null

  get group() {
    if (this.config) {
      return this.$store.myGetters['accounts/group/getById'](this.config.groupId)
    }
    return undefined
  }

  get groupName() {
    return this.group ? this.group.name : ''
  }

  public handleClose() {
    this.dialog = false
  }

  public created() {
    eventhub.$on(
      'open-view-details-dialog',
      ({
        config,
        task,
        stories,
      }: {
        config: KeyMap<any>
        task: TaskModel
        stories: TaskArticleModel[]
      }) => {
        this.dialog = true
        this.task = task
        this.stories = stories
        this.config = config
      },
    )
  }

  public beforeDestroy() {
    eventhub.$off('open-view-details-dialog')
  }
}
