




























































































import { Component, Prop } from 'vue-property-decorator'
import { SearchWithNestedParams } from '@technology/collaborate-next-js/dist/types/modules/task-search/params'
import {
  TaskArticlePublishStatusModel,
  TaskModel,
} from '@technology/collaborate-next-js/dist/types/models'
import { endOfDay, startOfDay } from 'date-fns'
import Vue from 'vue'

import CloseButton from '~/components/common/widget/buttons/CloseButton.vue'
import CustomButtonGroup from '~/components/common/CustomButtonGroup.vue'
import DateButtonDisplay from '~/components/common/widget/displays/DateButtonDisplay.vue'
import DateControl from '~/components/common/widget/controls/DateControl.vue'
import EnlargeWidgetButton from '~/components/common/widget/buttons/EnlargeWidgetButton.vue'
import MobileWrapper from '~/components/common/widget/wrappers/MobileWrapper.vue'
import Preload from '~/components/common/Preload.vue'
import RefreshButton from '~/components/common/widget/buttons/RefreshButton.vue'
import ShrinkWidgetButton from '~/components/common/widget/buttons/ShrinkWidgetButton.vue'
import TodayButton from '~/components/common/widget/buttons/TodayButton.vue'
import WidgetNameDisplay from '~/components/common/widget/displays/WidgetNameDisplay.vue'

import DailyTaskListContent from '~/components/dashboard/widget/collaborate/dailyTaskListWidget/content/Content.vue'
import DailyTaskListHeader from '~/components/dashboard/widget/collaborate/dailyTaskListWidget/header/Header.vue'

import constant from '~/components/dashboard/widget/collaborate/common/constant'

import { WidgetConfigEntity } from '~/apollo/types'
import { dateFormat } from '~/filters'
import util from '~/util'
@Component({
  components: {
    CloseButton,
    DailyTaskListContent,
    DailyTaskListHeader,
    CustomButtonGroup,
    DateControl,
    DateButtonDisplay,
    EnlargeWidgetButton,
    MobileWrapper,
    Preload,
    RefreshButton,
    ShrinkWidgetButton,
    TodayButton,
    WidgetNameDisplay,
  },
  filters: {
    dateFormat,
  },
  name: 'dashboard-collaborate-daily-task-list-widget',
})
export default class DailyTaskListWidget extends Vue {
  @Prop() public widget: WidgetConfigEntity
  @Prop() public widgetId: string
  @Prop() public config: KeyMap<any>

  public refreshInterval = 30000
  public enableCronjob = true

  public cancelLoading = false
  public dataLoading = false
  public refreshLoading = false
  public fetchDatetime = new Date()
  public dataIds: string[] = []
  public intervalId: NodeJS.Timeout

  public widgetSelectedDate: string = dateFormat(new Date(), 'YYYY-MM-DD')
  public widgetDateSelected = false

  get isMobile() {
    return this.$store.myGetters.getIsMobile
  }

  get containerClasses() {
    const classes = []
    // if (this.widget.size) {
    //   classes.push(`xs${this.widget.size}`)
    // } else {
    classes.push('xs12')
    // }
    return classes
  }

  get showEnlargeButton() {
    return this.widget.size && this.widget.size === '6'
  }

  get showShrinkButton() {
    return !this.widget.size || this.widget.size === '12'
  }

  public handleCloseBtnClick() {
    this.$alertDialog.showDialog({
      title: 'Warning',
      message: 'Are you sure to remove this widget?',
      buttons: [
        {
          text: 'Yes',
          color: 'primary',
          onClick: async () => {
            this.cancelLoading = true
            await this.$store.myActions.deleteWidgetConfig({
              widgetConfigId: this.widgetId,
            })
            this.cancelLoading = false
          },
        },
        {
          text: 'Cancel',
        },
      ],
    })
  }

  public async handleConfigUpdate(config: KeyMap<any>) {
    await this.$store.myActions.updateWidgetConfig({
      widgetConfigId: this.widgetId,
      body: {
        config: {
          ...config,
        },
      },
    })
  }

  public async handleResizeBtnClick(size: string) {
    await this.$store.myActions.updateWidgetConfig({
      widgetConfigId: this.widgetId,
      body: {
        size,
      },
    })
  }

  public async handleRefreshBtnClick() {
    this.sendGAAction('event', 'DTL', 'Click', 'Refresh')
    this.refreshLoading = true
    await this.fetchData(false)
    this.refreshLoading = false
  }

  // TODO: wait for vuetify date-range component
  // refs: https://github.com/vuetifyjs/vuetify/issues/1646
  // Date range support
  public handleTodayClick() {
    const today = dateFormat(new Date(), 'YYYY-MM-DD')
    this.widgetSelectedDate = today
    this.widgetDateSelected = false
    this.fetchData()
  }

  public handleWidgetDateClose() {
    this.widgetDateSelected = false
  }

  public handleWidgetDateChange(date: string) {
    this.widgetSelectedDate = date
    this.widgetDateSelected = false
    this.fetchData()
  }

  public handleWidgetDateClick() {
    this.widgetDateSelected = true
  }

  // end

  public async fetchData(withLoading = true, body = this.getFetchBody()) {
    this.clearBackgroundJob()
    withLoading && (this.dataLoading = true)
    try {
      const result = await this.$store.myActions.fetchTopicManagement({
        ...body,
      })

      let tasks = result.data
      const pubStatuses = result.nested?.taskArticlePublishStatuses ?? []
      if (result.nested && result.nested.relatedTasks) {
        tasks = [...tasks, ...result.nested.relatedTasks]
      }

      const userIds = result.data.reduce((acc, task) => {
        if (task.assigneeIds) acc.concat(task.assigneeIds)
        return acc
      }, [] as string[])

      await Promise.all([
        this.fetchGroupConfigs(),
        this.fetchCollaborateAppConfig(),
        this.fetchRelatedUser(tasks, pubStatuses),
        this.fetchUsers(userIds),
      ])

      // TODO: Change dataIds to taskIds in DailyTaskWidget [ ]

      // Sort by event datetime
      this.dataIds = result.data
        .sort((taskA, taskB) => {
          const groupNameA = this.$store.myGetters.getGroupName(taskA.boardId).toLowerCase()
          const groupNameB = this.$store.myGetters.getGroupName(taskB.boardId).toLowerCase()

          const boardA = this.$store.myGetters.getBoardById(taskA.boardId)
          const boardNameA = boardA ? boardA.name.toLowerCase() : ''

          const boardB = this.$store.myGetters.getBoardById(taskB.boardId)
          const boardNameB = boardB ? boardB.name.toLowerCase() : ''

          if (groupNameA < groupNameB) {
            return -1
          } else if (groupNameA > groupNameB) {
            return 1
          } else if (groupNameA === groupNameB) {
            if (boardNameA < boardNameB) {
              return -1
            }
            if (boardNameA > boardNameB) {
              return 1
            }
          }
          return 0
        })
        .map(task => task.id)
      this.fetchDatetime = new Date()
    } catch (e) {
      const err = e as any
      if (util.isObject(err.body)) {
        const { code, message } = err.body
        this.$store.myActions.SHOW_DIALOG({
          app: 'collaborate',
          type: 'error',
          code,
          description: message,
        })
      } else {
        console.log(err)
      }
    }
    withLoading && (this.dataLoading = false)
    this.enableCronjob && this.setNextJob()
  }

  public async fetchGroupConfigs() {
    return this.$store.myActions.fetchGroupConfigs()
  }

  public async fetchCollaborateAppConfig() {
    if (!this.$store.myGetters.getCollaborateAppConfig) {
      return this.$store.myActions.fetchCollaborateAppConfig()
    }
  }

  public async fetchRelatedUser(tasks: TaskModel[], pubStatuses: TaskArticlePublishStatusModel[]) {
    const userIds = util
      .distinct([
        ...util.flatten(tasks.map(task => [...(task.assigneeIds || []), task.createdBy])),
        ...pubStatuses.map(p => p.userId),
      ])
      .filter(
        userId =>
          !util.checkEmpty(userId) && !this.$store.myGetters['accounts/user/getById'](userId),
      )
    if (userIds.length > 0) {
      return this.$store.myActions['accounts/user/fetchList']({
        q: userIds.join(','),
        type: 'ids',
      })
    }
  }

  public async fetchUsers(userIds: string[]) {
    if (userIds.length === 0) return

    const distinctUserIds = util.distinct(userIds).filter(userId => !util.checkEmpty(userId))
    const queryUserIds = distinctUserIds.filter(userId =>
      util.checkEmpty(this.$store.myGetters['accounts/user/getById'](userId)),
    )
    if (queryUserIds.length > 0) {
      await this.$store.myActions['accounts/user/fetchList']({
        q: queryUserIds.join(','),
        type: 'ids',
      })
    }

    this.$store.myActions['accounts/user/fetchList']({
      q: queryUserIds.join(','),
      type: 'ids',
    })
  }

  // Background job related
  public clearBackgroundJob() {
    this.intervalId && clearTimeout(this.intervalId)
  }

  public setNextJob() {
    this.intervalId = setTimeout(async () => {
      this.refreshLoading = true
      await this.fetchData(false)
      this.refreshLoading = false
    }, this.refreshInterval)
  }

  public async created() {
    this.sendGAAction('event', 'TM', 'Load')
    await this.fetchData()
  }

  public destroyed() {
    this.enableCronjob = false
    this.clearBackgroundJob()
  }

  private getFetchBody() {
    const baseBody: SearchWithNestedParams = {
      taskSearch: {
        groupIds: this.$store.myGetters.getValidGroupIds,
      },
    }

    if (this.config.dateType === constant.dateTypes.eventDate.value) {
      baseBody.taskSearch.eventDateStart = startOfDay(this.widgetSelectedDate).toISOString()
      baseBody.taskSearch.eventDateEnd = endOfDay(this.widgetSelectedDate).toISOString()
    } else {
      baseBody.taskSearch.issueDateStart = startOfDay(this.widgetSelectedDate).toISOString()
      baseBody.taskSearch.issueDateEnd = endOfDay(this.widgetSelectedDate).toISOString()
    }

    return baseBody
  }
}
