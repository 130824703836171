








import { Component } from 'vue-property-decorator'
import Vue from 'vue'
import WidgetCollapse from '~/types/widgetCollapse'

@Component({
  name: 'dashboard-collaborate-daily-task-list-widget-add-widget-btn',
})
export default class AddWidgetBtn extends Vue {
  public widgetType = 'collab-daily-task-list-widget'

  public async handleClick() {
    await this.$store.myActions.createWidgetConfig({
      body: {
        list: [
          {
            name: 'Daily Task List for Editorial',
            type: this.widgetType,
            config: {
              collapseState: WidgetCollapse.Full,
            },
          },
        ],
      },
    })
  }
}
