











































































import { Component, Prop } from 'vue-property-decorator'
import { PublishStatusEvent } from '@technology/collaborate-next-js'
import {
  TaskArticlePublishStatusModel,
  TaskArticleStatusModel,
} from '@technology/collaborate-next-js/dist/types/models'
import { storyStatusMap } from '@technology/compose-js'
import Vue from 'vue'

import Collapse from '~/components/common/Collapse.vue'
import CounterDisplay from '~/components/common/widget/displays/CounterDisplay.vue'
import DailyTaskListCardListing from '~/components/dashboard/widget/collaborate/dailyTaskListWidget/content/CardListing.vue'
import DailyTaskListDataListing from '~/components/dashboard/widget/collaborate/dailyTaskListWidget/content/DataListing.vue'

import util from '~/util'

@Component({
  components: {
    CounterDisplay,
    Collapse,
    DailyTaskListCardListing,
    DailyTaskListDataListing,
  },
  name: 'dashboard-collaborate-daily-task-list-widget-content',
})
export default class Content extends Vue {
  @Prop() public config: KeyMap<any>
  @Prop() public taskIds: string[]

  public wrapperElement: any = null
  public calcTargetElement: any = null
  public createdSelected = false
  public editingSelected = false
  public publishedSelected = false
  public $refs: any

  public filterFunctionChain: KeyMap<(filteredTaskIds: string[], taskIds: string[]) => string[]> =
    {}

  get isMobile() {
    return this.$store.myGetters.getIsMobile
  }

  get tasks() {
    return this.$store.myGetters.getTasksByIds(this.taskIds)
  }

  get parentTaskIds() {
    return this.$store.myGetters.getParentTaskIdsByTaskIds(this.taskIds) ?? []
  }

  get filteredTaskIds() {
    const taskIds = this.tasks.map(task => task.id)
    // Recalculate the collapse height
    this.$refs.collapseRef && this.$nextTick(this.$refs.collapseRef.recalculateHeight)

    if (Object.keys(this.filterFunctionChain).length) {
      const filteredResult = Object.values(this.filterFunctionChain).reduce(
        (acc: string[], filterFunc) => filterFunc(acc, taskIds),
        [],
      )
      // Remove duplicated value
      return util.distinct(filteredResult)
    }

    return taskIds
  }

  get relatedArticles() {
    return this.$store.myGetters.getTaskArticlesByTaskIds(this.parentTaskIds)
  }

  get articleIds() {
    return this.relatedArticles.map(article => article.id)
  }

  get taskIdToArticleIdMap() {
    return this.tasks.reduce((acc, task) => {
      const targetTaskId = task.parentTaskId ?? task.id
      const targetArticle = this.relatedArticles.find(article => article.taskId === targetTaskId)
      if (targetArticle) acc[targetArticle.taskId] = targetArticle.id

      return acc
    }, {} as Record<string, string>)
  }

  get articleIdToArticlePublishStatusMap() {
    const publishStatuses = this.$store.myGetters
      .getTaskArticlePublishStatusesByArticleIds(this.articleIds)
      .sort((a, b) => util.sortDate(a.updatedAt, b.updatedAt))

    return publishStatuses.reduce((acc, pubStatus) => {
      acc[pubStatus.articleId] = this.comparePubStatus(acc[pubStatus.articleId], pubStatus)
      return acc
    }, {} as Record<string, TaskArticlePublishStatusModel>)
  }

  get articleIdToArticleStatusMap() {
    return this.$store.myGetters
      .getTaskArticleStatusesByArticleIds(this.articleIds)
      .reduce((acc, status) => {
        if (!acc[status.articleId]) {
          acc[status.articleId] = status
        } else if (status.createdAt > acc[status.articleId].createdAt) {
          acc[status.articleId] = status
        }

        return acc
      }, {} as Record<string, TaskArticleStatusModel>)
  }

  // Priority 1
  get publishedStoryCount() {
    const pubStatuses = Object.values(this.articleIdToArticlePublishStatusMap)
    return pubStatuses.filter(p => p.event === PublishStatusEvent.Published).length
  }

  // Priority 2
  get scheduledPublishedCount() {
    const pubStatuses = Object.values(this.articleIdToArticlePublishStatusMap)
    return pubStatuses.filter(p => p.event === PublishStatusEvent.Scheduled).length
  }

  // Priority 3
  get stageCount() {
    const pubStatuses = Object.values(this.articleIdToArticlePublishStatusMap)
    return pubStatuses.filter(p => p.event === PublishStatusEvent.Staged).length
  }

  // Priority 4
  get createdCount() {
    return Object.values(this.articleIdToArticleStatusMap).filter(
      article => article.status === storyStatusMap.create,
    ).length
  }

  // Priority 4
  get editingCount() {
    return Object.values(this.articleIdToArticleStatusMap).filter(
      article => article.status === storyStatusMap.for_editing,
    ).length
  }

  // Priority 4
  get subForChannelCount() {
    return Object.values(this.articleIdToArticleStatusMap).filter(
      article => article.status === storyStatusMap.sub_for_channel,
    ).length
  }

  get storiesCount() {
    return this.relatedArticles.length
  }

  get storySelected() {
    return !this.createdSelected && !this.editingSelected && !this.publishedSelected
  }

  public handleCollapseChange(collapseState: number) {
    this.$emit('config-update', {
      ...this.config,
      collapseState,
    })
  }

  public handleCreatedClick() {
    this.createdSelected = !this.createdSelected
    if (this.createdSelected) {
      Vue.set(
        this.filterFunctionChain,
        'created',
        (filteredTaskIds: string[], taskIds: string[]) => {
          const createTaskIds = taskIds.reduce((ids: string[], taskId) => {
            const articleId = this.taskIdToArticleIdMap[taskId]
            const status = this.articleIdToArticleStatusMap[articleId]?.status
            if (status) status === storyStatusMap.create && ids.push(taskId)

            return ids
          }, [])
          return filteredTaskIds.concat(createTaskIds)
        },
      )
    } else {
      Vue.delete(this.filterFunctionChain, 'created')
    }
  }

  public handleEditingClick() {
    this.editingSelected = !this.editingSelected
    if (this.editingSelected) {
      Vue.set(
        this.filterFunctionChain,
        'editing',
        (filteredTaskIds: string[], taskIds: string[]) => {
          const forEditingTaskIds = taskIds.reduce((ids: string[], taskId) => {
            const articleId = this.taskIdToArticleIdMap[taskId]
            const status = this.articleIdToArticleStatusMap[articleId]?.status
            if (status) status === storyStatusMap.for_editing && ids.push(taskId)

            return ids
          }, [])
          return filteredTaskIds.concat(forEditingTaskIds)
        },
      )
    } else {
      Vue.delete(this.filterFunctionChain, 'editing')
    }
  }

  public handlePublishedClick() {
    this.publishedSelected = !this.publishedSelected
    if (this.publishedSelected) {
      Vue.set(
        this.filterFunctionChain,
        'published',
        (filteredTaskIds: string[], taskIds: string[]) => {
          const publishedTaskIds = taskIds.reduce((ids: string[], taskId) => {
            const articleId = this.taskIdToArticleIdMap[taskId]
            const event = this.articleIdToArticlePublishStatusMap[articleId]?.event
            if (event) event === PublishStatusEvent.Published && ids.push(taskId)

            return ids
          }, [])

          return filteredTaskIds.concat(publishedTaskIds)
        },
      )
    } else {
      Vue.delete(this.filterFunctionChain, 'published')
    }
  }

  public handleStoryClick() {
    this.createdSelected = false
    this.publishedSelected = false
    this.editingSelected = false
    Vue.delete(this.filterFunctionChain, 'created')
    Vue.delete(this.filterFunctionChain, 'editing')
    Vue.delete(this.filterFunctionChain, 'published')
  }

  public handleDataPresentInit() {
    const wrapperElements = this.isMobile
      ? this.$refs.dataPresentRef.$el.getElementsByClassName('v-data-iterator')
      : this.$refs.dataPresentRef.$el.getElementsByClassName('v-data-table')
    const calcTargetElements = this.isMobile
      ? this.$refs.dataPresentRef.$el.getElementsByClassName('v-data-content')
      : this.$refs.dataPresentRef.$el.getElementsByClassName('v-data-table__wrapper')
    if (wrapperElements.length && calcTargetElements.length) {
      this.wrapperElement = wrapperElements[0]
      this.calcTargetElement = calcTargetElements[0]
      if (this.wrapperElement && this.calcTargetElement) {
        // Recalculate the collapse height
        this.$refs.collapseRef && this.$nextTick(this.$refs.collapseRef.recalculateHeight)
      }
    }
  }

  public comparePubStatus(
    o: Nullable<TaskArticlePublishStatusModel>,
    n: TaskArticlePublishStatusModel,
  ) {
    // Sequence is Published > Cancelled || Scheduled > Staged
    if (!o) return n
    if (n.event === PublishStatusEvent.Published) return n
    if (o.event === PublishStatusEvent.Published) return o
    if (n.event === PublishStatusEvent.Cancelled) return n
    if (n.event === PublishStatusEvent.Scheduled) return n
    if (o.event === PublishStatusEvent.Cancelled) return o
    if (o.event === PublishStatusEvent.Scheduled) return o
    if (n.event === PublishStatusEvent.Staged) return n
    if (o.event === PublishStatusEvent.Staged) return o
    return n
  }
}
