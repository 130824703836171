















import { Component, Prop, Vue } from 'vue-property-decorator'

import constant from '~/components/dashboard/widget/collaborate/common/constant'
@Component({
  name: 'dashboard-collaborate-common-inputs-date-type-select',
})
export default class DateTypeSelect extends Vue {
  @Prop()
  public value: string
  @Prop(Boolean)
  public disabled: boolean
  @Prop({
    default: '',
  })
  public label: string
  @Prop({
    default: false,
    type: Boolean,
  })
  public clearable: boolean

  public $refs: any

  get dateTypes() {
    return Object.values(constant.dateTypes)
  }

  public updateValue(value: string) {
    this.$emit('input', value)
  }
}
