





import { Component } from 'vue-property-decorator'
import Vue from 'vue'

import ViewDetailsDialog from '~/components/dashboard/widget/collaborate/dailyTaskListWidget/viewDetailsDialog/Dialog.vue'

@Component({
  components: {
    ViewDetailsDialog,
  },
  name: 'dashboard-collaborate-daily-task-list-widget-placeholder',
})
export default class Placeholder extends Vue {}
