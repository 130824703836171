






import { Component } from 'vue-property-decorator'
import Vue from 'vue'

import TitleDisplay from '~/components/common/widget/displays/TitleDisplay.vue'

@Component({
  components: {
    TitleDisplay,
  },
  name: 'dashboard-collaborate-daily-task-list-widget-header',
})
export default class Header extends Vue {}
